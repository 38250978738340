<template>
  <div v-if="lotInsideList" class="lot-inside-area">
    <div
        v-for="(item, index) in lotInsideList"
        :key="index"
        class="inside-item">
      <div class="image-module">
        <router-link class="image-module__link" :to="`/app/lotInside/detail/${item.lotInsideId}`">
          <figure class="image">
            <img :src="item.thumbnailImage || '/images/default-img-blue.png'" class="image__content cover" alt />
          </figure>
        </router-link>
      </div>
      <div class="inside-item-details">
        <div class="inside-item-top">
          <strong class="inside-item__name">{{ item.title }}</strong>
          <span class="inside-item__date">{{ format(new Date(item.updatedAt || item.createdAt), 'MM월 dd일') }}</span>
        </div>
        <p class="inside-item__description">
          {{ content(item.contents) }}
        </p>
        <router-link class="inside-item__link" :to="`/app/lotInside/detail/${item.lotInsideId}`">
          <span class="blind">자세히 보기</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onUpdated, PropType, ref } from 'vue';
import { format } from 'date-fns';
import router from '@/router';
import useCategoryOptions from '@/mixins/useCategoryOptions';

export default defineComponent({
  name: 'ListTable',
  components: {
  },
  props: {
    lotInsideList: Object as PropType<any>,
  },
  setup: function (props, { emit }) {
    const { categoryDepth1Obj } = useCategoryOptions();
    const targetOrder = ref(null);

    onUpdated(() => {
      console.log('members', props.lotInsideList);
    });


    const handleClickRow = (item) => {
      // 상품상세로 이동
      router.push({
        path: `/app/member/detail/${item.memberId}`,
      });
    };

    const content = (contents) => {
      const regex = /(<([^>]+)>)/ig
      return contents?.replace(regex, '')
    }

    return {
      targetOrder,
      format,
      content,
      handleClickRow,
      categoryDepth1Obj,
    };
  },
});
</script>

<style lang="scss" scoped>
.lot-inside-area {
  margin-top: 30px;
  display: grid;
  grid-row-gap: 30px;
  row-gap: 30px;
  grid-column-gap: 30px;
  -moz-column-gap: 30px;
  column-gap: 30px;
  grid-template-columns: 427px 427px 427px;
}
.blind {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1px;
  width: 1px;
  height: 1px;
}
.inside-item {
  position: relative;
  width: 427px;

  .image-module {
    position: relative;
    .image-module__link {
      overflow: hidden;
    }
    .image {
      position: relative;
      display: block;
      height: 215px;
      width: 100%;
      &:after {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.05);
        z-index: 2;
        content: "";
      }
      .image__content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        &.cover {
          object-fit: cover;
        }
      }
    }
  }
  &.inside-list {
  }
  &-details {
    height: 95px;
    background: #fff;
    padding: 15px 16px 20px;
    box-sizing: border-box;
    position: relative;
  }
  .inside-item {
    &-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__description {
      font-size: 14px;
      font-weight: 400;
      line-height: 1.25;
      margin-top: 13px;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
      word-wrap: break-word;
      white-space: normal;
      box-sizing: border-box;
      /* autoprefixer: ignore next */
      -webkit-box-orient: vertical;
    }
    &__name {
      max-width: calc(100% - 51px);
      font-size: 18px;
      font-weight: 800;
      line-height: 1.125;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      word-break: break-all;
      -webkit-box-orient: vertical;
    }
    &__date {
      font-size: 14px;
      font-weight: 400;
      line-height: 1.125;
      flex: none;
    }
  }
  &__link {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
  }
}

</style>
