
import { defineComponent, PropType } from 'vue';
import { LotInsideListParams } from './index.vue';

export default defineComponent({
  name: 'ListFilter',
  components: {
  },
  props: {
    params: {
      type: Object as PropType<LotInsideListParams>,
      required: true,
    },
    handleChangeFilterParams: { type: Function, required: true },
    onClickSearch: { type: Function, required: true },
  },
  methods: {},
  setup(props) {
    const updateParams = (key: string, value: any) => {
      console.log('updateParams', key, value);
      props.handleChangeFilterParams(key, value);
    };

    return {
      updateParams,
    };
  },
});
