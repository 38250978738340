<template>
  <Container>
    <!-- Filter -->
    <ListFilter
      :params="filterParams"
      :handleChangeFilterParams="handleChangeFilterParams"
      :onClickSearch="executeSearch"
    />

    <ListTable
      :lotInsideList="lotInsideList.data"
      @refresh="fetchLotInsideList"
    ></ListTable>

    <div class="mt-10">
      <Pagination
        :initialPage="filterParams.page"
        :pageSize="parseInt(filterParams.pageSize)"
        :onChange="handleChangePage"
        :onChangePageSize="handleChangePageSize"
        :total="lotInsideList.total"
      ></Pagination>
    </div>
    <div class="flex items-center justify-center">
      <button
          @click="gotoRegister"
          class="
                        w-60
                        h-16
                        border border-black
                        shadow
                        flex
                        items-center
                        justify-center
                        mt-10
                        mx-auto
                        admin-button
                      "
      >
        <IconBase>
          <CheckCircleIcon></CheckCircleIcon>
        </IconBase>
        <span class="ml-2">등록하기</span>
      </button>
    </div>
  </Container>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch, reactive, toRef } from 'vue';
import { useRoute } from 'vue-router';
import Container from '@/components/Container.vue';
import Pagination from '@/components/Pagination/index.vue';
import router from '@/router/index';
import ListFilter from './ListFilter.vue';
import ListTable from './ListTable.vue';
import partnerAPI from '@/service/partnerAPI';
import format from 'date-fns/format/index.js';
import datePattern from '@/constants/datePattern';
import IconBase from '@/components/icons/IconBase.vue';
import CheckCircleIcon from '@/components/icons/CheckCircleIcon.vue';

export type LotInsideListParams = {
  keyword?: string;
  page: number;
  pageSize: number;
};

export default defineComponent({
  name: 'LotInsideList',
  components: {
    Container,
    ListFilter,
    ListTable,
    Pagination,
    IconBase,
    CheckCircleIcon,
  },
  props: {},
  setup() {
    const route = useRoute();
    const query = route.query;

    const filterParams = reactive<LotInsideListParams>({
      keyword: (query.keyword as string) || '',
      page: Number(query.page) || 0,
      pageSize: Number(query.pageSize) || 12,
    });

    const lotInsideList = ref({
      success: true,
      data: [],
      message: '',
      total: 0,
    });


    const handleChangeFilterParams = (key: string, value: any) => {
      filterParams[key] = value;
    };

    const handleChangePage = (page: number) => {
      filterParams.page = page - 1;
      executeSearch();
    };
    const handleChangePageSize = (pageSize: number) => {
      filterParams.page = 0;
      filterParams.pageSize = pageSize;
      executeSearch();
    };

    /**
     * 검색 실행
     */
    const executeSearch = () => {
      router.push({
        path: window.location.pathname,
        query: {
          keyword: filterParams.keyword || '',
          page: filterParams.page || 0,
          pageSize: filterParams.pageSize || 10,
        },
      });
    };

    const getAppliedFilterParams = () => {
     return {
       keyword: filterParams.keyword,
       page: filterParams.page,
       pageSize: filterParams.pageSize,
     };
    }

    const fetchLotInsideList = async () => {
      console.group('fetchLotInsideList');
      console.log('finalFilterParams', getAppliedFilterParams());
      console.groupEnd();

      try {
        const { data } = await partnerAPI.adminLotInside.adminLotInsideList(
            getAppliedFilterParams()
        );
        lotInsideList.value = data as any;
      } catch ({ response }) {
        console.error(response.data);
        alert(response.data.message);
      }
    };

    const gotoRegister = () => {
      router.push({
        path: `/app/lotInside/register`
      })
    }

    onMounted(fetchLotInsideList);

    watch([filterParams], (newFilter, newIsFilterOn) => {
      console.log('watch filter', newFilter, newIsFilterOn);
      fetchLotInsideList();
    });

    return {
      filterParams,
      lotInsideList,
      handleChangePage,
      handleChangePageSize,
      executeSearch,
      handleChangeFilterParams,
      fetchLotInsideList,
      gotoRegister,
    };
  },
});
</script>

<style lang="scss">
@import "../../../assets/style/label";
</style>
