
import { defineComponent, onUpdated, PropType, ref } from 'vue';
import { format } from 'date-fns';
import router from '@/router';
import useCategoryOptions from '@/mixins/useCategoryOptions';

export default defineComponent({
  name: 'ListTable',
  components: {
  },
  props: {
    lotInsideList: Object as PropType<any>,
  },
  setup: function (props, { emit }) {
    const { categoryDepth1Obj } = useCategoryOptions();
    const targetOrder = ref(null);

    onUpdated(() => {
      console.log('members', props.lotInsideList);
    });


    const handleClickRow = (item) => {
      // 상품상세로 이동
      router.push({
        path: `/app/member/detail/${item.memberId}`,
      });
    };

    const content = (contents) => {
      const regex = /(<([^>]+)>)/ig
      return contents?.replace(regex, '')
    }

    return {
      targetOrder,
      format,
      content,
      handleClickRow,
      categoryDepth1Obj,
    };
  },
});
